import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { isBrowser } from '../utils/general'
import { navigate } from 'gatsby'
const SignOut = () => {
	useEffect(() => {
		localStorage.removeItem('token')
		sessionStorage.removeItem('token')
		let token = isBrowser() ? JSON.parse(localStorage.getItem('token')) : {}
		let sessionToken = isBrowser()
			? JSON.parse(sessionStorage.getItem('token'))
			: {}
		console.log(token)
		token === null || sessionToken === token ? navigate('/') : null
	})
	return ''
}

export default SignOut
